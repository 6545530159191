.Header_hero__EHaSz {
  max-width: 62.4rem;
  padding-bottom: 2.4rem;
}
.Header_title__KBXs9 {
  margin-bottom: 1.1rem;
  margin-top: 0;
}
.LinkedHeading_heading__RezUA {
  position: relative;
}
.FullWidth_fullWidthBustOut__a58QL {
  max-width: 118.4rem;
  width: calc(100vw - 48rem);
}

.posistion_content {
  position: absolute;
  left: 150px;
  width: 200px;
  height: 120px;
  text-align: left;
  /* float: left; */
  /* border: 3px solid green; */
}

.Schema_schema__eflLE .Schema_tableHeader__BCZlp {
  color: #111;
  margin-bottom: 0;
}

.Touchable-module_resetButtonOrLink__hwe7O {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-family: Cern, Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  text-align: left;
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
}
.Schema_schema__eflLE .Schema_expandAllButton__oigym {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #555;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  justify-self: flex-end;
  min-width: 9.6rem;
  -webkit-transition: color 0.1s ease-in-out;
  transition: color 0.1s ease-in-out;
}

.Icons-module_icon__3ePq6 {
  width: 2.4rem;
  height: 2.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.HTTPStatus-chit {
  background: #12ca93;
}

.HTTPStatus {
  --HTTPStatus-font: var(--font-family-mono);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-family: var(--HTTPStatus-font);
  padding: 5px 3px;
}

.editor {
  font-family: sans-serif;
  text-align: center;
}

.editor .jsoneditor {
  border: none;
  background-color: transparent;
}

.editor .jsoneditor .jsoneditor-outer table.jsoneditor-tree {
  background-color: #f7fafc;
  padding: 1rem;
}

.mock-API-response .jsoneditor {
  border: none;
}
.jsoneditor-menu > button,
.jsoneditor-menu > .jsoneditor-modes > button {
  height: unset !important;
}

.jsoneditor-menu > button,
.jsoneditor-menu > .jsoneditor-modes > button {
}

.jsoneditor-tree button.jsoneditor-button {
  /* width: 100%; */
  /* height: 26px; */
  /* line-height: 26px; */
  padding: 0;
  margin: 0;
  /* border-bottom: 1px solid #d3d3d3; */
  box-sizing: border-box;
  color: #808080;
  /* background-color: #ebebeb; */
  overflow: hidden;
  font-family: arial, sans-serif;
  font-size: 14px;
}
.outer.has-nav-bar.has-main-menu-bar {
  padding-top: unset !important;
}

.jsoneditor-menu {
  height: unset !important;
  background-color: unset !important;
  border-bottom: unset !important;
}
.jsoneditor-outer.has-nav-bar.has-main-menu-bar {
  padding-top: unset !important;
}
.jsoneditor {
  position: unset !important;
}

.jsoneditor-tree button.jsoneditor-button {
  height: unset !important;
}

.method-example-request {
  background: #c1c9d2;
  background: var(--example-request-bg);
}

.method-example-request-topbar {
  background: #e3e8ee;
  background: var(--example-request-topbar-bg);
  padding: 4px;
  padding: var(--sp4);
}

.LangSwitcher {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.method-example-request-title {
  color: #a3acb9;
  color: var(--example-request-topbar-color);
  padding: 8px;
  padding: var(--sp8);
  padding-left: 12px;
  padding-left: var(--sp12);
}
.Box-root {
  box-sizing: border-box;
}
.Flex-flex {
  display: flex;
}
.SVG--color svg {
  fill: currentColor;
}
.MethodExampleTopbarButton {
  display: flex;
  align-items: center;
}
.parameterBackground {
  background-color: transparent !important;
}

/* .parameterBackground {
  background-color: #4f566b !important;
  color: white !important;
} */
/* .css-46bh2p-MuiCardContent-root:last-childt {
  background-color: #4f566b !important;
} */
/* .jsoneditor-tree tbody {
  background-color: #4f566b !important;
  border-color: #4f566b !important;
}
.jsoneditor {
  border-color: #4f566b !important;
}
.css-ljvmaf-MuiGrid-root {
  background-color: #3c4257 !important;
} */
.editor td.jsoneditor-tree div.jsoneditor-field {
  /* color: #697386; */
  color: #000;
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.editor td.jsoneditor-tree div.jsoneditor-empty {
  border-color: #0c314e;
  border-style: dashed;
  border-radius: 2px;
  font-size: 16px;
  background-color: #fff;
  color: #0c314e;
  font-family: "Open Sans", sans-serif;
}
.editor td.jsoneditor-tree div.jsoneditor-empty::after {
  color: #697386;
  font-family: "Open Sans", sans-serif;
}
.editor td.jsoneditor-tree div.jsoneditor-value {
  min-width: 80px;
}
.mock-API-response td.jsoneditor-tree div.jsoneditor-field {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
