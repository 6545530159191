.customdatepicker {
  position: relative;
  /* padding: 1px; */

  /* right: 4%; */
  justify-content: left;
  z-index: 100;
  box-shadow: 0px 0px 12px 1px #d8d8d8;
}
.dashBoard_date_picker {
  position: relative;
  /* padding: 1px; */
  right: 4%;
  justify-content: left;
  z-index: 100;
  /* box-shadow: 0px 0px 12px 1px #d8d8d8; */
}
.DashDateRangePicker {
  position: absolute !important;
  right: 10% !important;
  display: flex;
  justify-content: right !important;
  z-index: 100 !important;
}
.yearDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: green;
}

.yearDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: red;
}
